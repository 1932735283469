import React, { useState } from 'react';
import Iframe from 'react-iframe'


export default function Resume() {
 const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return <div className="resume-wrapper">
<Iframe url="/Firas-Kudsy.pdf"
        width="90%"
        height="800px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
              <div className="space"></div>

  </div>;
}
