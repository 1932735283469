import React from 'react';

export default function Experiance() {
  return (
    <div className="contents">
      <h2>Experiance</h2>
      <div className="card">
        <h2>
          <strong>TD Bank</strong> - senior full stack developer
        </h2>
        <h3>
          <ul>
            <li>Technical Architecture Design for multiple QE custom tools.</li>
            <li>
              Develop and Maintain various reporting web applications for Coverage, ROI, test executions, accessibility code, and environment downtime. The
              stack includes ( MS SQL, MongoDB, NodeJS, NestJs, React.js, Angular, Nginx)
            </li>
            <li>Develop and Maintain various backend Rest API, services, and microservices to integrate HP ALM, Jira, JTMF with QE Custom tools.</li>
            <li>Managed time-sensitive updates, including content changes and database upgrades.</li>
            <li>Lead web application migration to production on Azure.</li>
          </ul>
        </h3>
      </div>
      <div className="card">
        <h2>
          <strong>Fitviser</strong> - full stack developer
        </h2>
        <h3>
          <ul>
            <li>
              Create innovative, interactive, and Scalable E-Commerce web applications. The stack includes ( Firebase, MongoDB, NodeJS, Loopback, Angular,
              Nginx, and Jenkins)
            </li>
            <li>Develop and Maintain User Dashboard Web Applications.</li>
            <li>Develop and Maintain Hybrid E-Commerce Mobile Dashboard with Ionic Framework.</li>
            <li>Take part in the technical architecture design process.</li>
          </ul>
        </h3>
      </div>
      <div className="card">
        <h2>
          <strong>Freelance</strong> - full stack developer
        </h2>
        <h3>
          <ul>
            <li>Developed a Hybrid Mobile Apps for Restaurant Ordering, Delivery and tracking using ionic framework, AngularJS and Node js</li>
            <li>
              Built a custom mobile app for “Basmayah City” using ionic framework to enable app users to follow their monthly payments, remaining amounts and to
              provide the user with their nominated apartment address
            </li>
            <li>Developed a scheduling web application using Angular2 and Firebase to manage employees tasks and resources</li>
            <li>
              Developed a full Auditing system using VB.NET, DevExpress and SQL Server, including chart tables, chart of accounts, daily vouchers, statement of
              account, balance and a full set of reports
            </li>
            <li>Built a native Android app for daily transactions, used for posting users transactions to the backend REST API server</li>
            <li>Designed and Customized wordpress sites with modern responsive web UI standards</li>
          </ul>
        </h3>
      </div>
      <div className="space"></div>
    </div>
  );
}
