import React from 'react';

export default function Skills() {
  return (
    <div className="contents">
      <h2>Skills</h2>
      <div className="sticky sticky1">
        <div>
          <p>Backend:-</p>
          <ul>
            <li>NodeJS / Express</li>
            <li>NestJS</li>
            <li>Loopback</li>
            <li>C# Rest API</li>
            <li>Rest API / GraphQL</li>
            <li>Python / Flask</li>
          </ul>
        </div>
      </div>
      <div className="sticky sticky2">
        <div>
          <p>Frontend:-</p>
          <ul>
            <li>HTML & CSS</li>
            <li>Angular</li>
            <li>React</li>
            <li>Ionic Framework</li>
            <li>Flutter</li>
          </ul>
        </div>
      </div>
   <div className="sticky sticky3">
        <div>
          <p>Database:-</p>
          <ul>
            <li>MS SQL</li>
            <li>MySql</li>
            <li>MongoDB</li>
            <li>Firebase & Firestore</li>
            <li>PostgreSQL</li>
          </ul>
        </div>
      </div>
        <div className="sticky sticky4">
        <div>
          <p>Languages:-</p>
          <ul>
            <li>Javascript / Typescript</li>
            <li>Python</li>
            <li>C# / VB.NET</li>

          </ul>
        </div>
      </div>
              <div className="sticky sticky5">
        <div>
          <p>Others:-</p>
          <ul>
            <li>Google Cloud</li>
            <li>Nginx</li>
            <li>Jenkins</li>
            <li>Git</li>
            <li>Web3</li>
            <li>Azure</li>

          </ul>
        </div>
      </div>
      <div className="space"></div>
    </div>
  );
}
